import { template as template_e8dd978e73274abf804297e8cb5ebf46 } from "@ember/template-compiler";
const WelcomeHeader = template_e8dd978e73274abf804297e8cb5ebf46(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
