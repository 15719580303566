import { template as template_380d971ef1544115a3e0cdc24c3bd19a } from "@ember/template-compiler";
const SidebarSectionMessage = template_380d971ef1544115a3e0cdc24c3bd19a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
