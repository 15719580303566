import { template as template_b44bce2f8d6b413996cd40f921885ee2 } from "@ember/template-compiler";
const FKText = template_b44bce2f8d6b413996cd40f921885ee2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
